// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-thank-you-js": () => import("./../../../src/pages/contact/thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/caseStudies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/caseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-previews-js": () => import("./../../../src/templates/previews.js" /* webpackChunkName: "component---src-templates-previews-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-think-js": () => import("./../../../src/templates/think.js" /* webpackChunkName: "component---src-templates-think-js" */)
}

